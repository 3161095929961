// images
import dmCPOThumb from '../images/jaguar/2019/preowned/Email_Icons_300x300__0002_18_67_668_Jaguar_ACPO_SE_DM_300x300-2-.png';
import dmCPO from '../images/jaguar/2019/preowned/18_67_668_Jaguar_ACPO_SE_DM_900x900-2-.jpg';
import emCPOThumb from '../images/jaguar/2019/preowned/Email_Icons_300x300__0003_18_67_668_Jaguar_ACPO_SE_Email_300x300-1-.png';
import emCPO from '../images/jaguar/2019/preowned/18_67_668_Jaguar_ACPO_SE_Email_900x900-1-.jpg';

import dmIPThumb from '../images/jaguar/2019/ipace/DM_300x300.png';
import dmIP from '../images/jaguar/2019/ipace/DM_900x900.jpg';
import emIPThumb from '../images/jaguar/2019/ipace/EM_300x300.png';
import emIP from '../images/jaguar/2019/ipace/EM_900x900.jpg';

export default [
  // {
  //   order: 1,
  //   active: true,
  //   attributes: {
  //     acquisitionFee: "",
  //     apr: "",
  //     aprCustomerCredit: "",
  //     aprMonths: "",
  //     customerCredit: "",
  //     dueAtSigning: "",
  //     leaseOffer: "",
  //     monthTerm: "",
  //     msrp: "",
  //     securityDeposit: "",
  //     totalOfLeasePayments: "",
  //     vehicle: "2019 I-PACE S",
  //     xxxxModels: "",
  //     amountDown: "",
  //     amountPerMonth: ""
  //   },
  //   id: 'I-PACE',
  //   type: 'I-PACE',
  //   campaignMatch: 'I-PACE',
  //   title: 'The All Electric Jaguar I-PACE Campaign',
  //   title2: 'The All Electric Jaguar I-PACE Campaign',
  //   hasClimateSelect: false,
  //   description: 'The all-electric Jaguar I-PACE — our first electric vehicle — continues to make history. The I-PACE won an unprecedented three titles at the 2019 World Car of the Year Awards: World Car of the Year, World Car Design of the Year, and World Green Car. To take advantage of this great momentum, we have created a conquest marketing opportunity. Our custom lists include prospects from alternative fuel base categories: Plug-in Hybrid, Lithium-Ion Battery – Electric, and Gas/Electric Hybrid fuel types and have an HHI greater than $150K. The lists also include current owners of BMW, Mercedes, Volvo, Cadillac, Porsche, Lexus and Audi SUVs, and Tesla. All lists are broken up into your AOR. This campaign consists of 1 Direct Mail and 2 Email follow-ups. The campaign is 100% co-op eligible, and no submission is necessary.',
  //   costs: {
  //     dm: 1.25,
  //     email: 0.11
  //   },
  //   images: {
  //     dmThumb: dmIPThumb,
  //     dmFull: dmIP,
  //     emThumb: emIPThumb,
  //     emFull: emIP
  //   },
  //   optionalFlag: false,
  //   vehicle: "2019 I-PACE S"
  // },
  // {
  //   order: 2,
  //   active: true,
  //   id: '2019-pre-owned-1',
  //   type: 'Approved Certified Pre-Owned Sales Event',
  //   campaignMatch: 'jaguar_cpo',
  //   title: 'Jaguar Approved Certified Pre-Owned Sales Event',
  //   title2: 'Approved Certified Pre-Owned Sales Event',
  //   hasClimateSelect: false,
  //   description: 'The Jaguar Approved Certified Pre-Owned Sales Event is a 3-month campaign that is localized for your retailer. Please choose two vehicles and model years to focus on, depending on the inventory you want to sell. This campaign highlights the attractive 0.9% APR National Sales Offer for up to 24 months. The conquests are luxury owners of competitor vehicles in market in your AOR. The customer lists are provided by JLRNA and include current CPO owners, disposers and owners of used vehicles in your AOR. This data-driven conquest campaign comes with one direct mail piece and two emails. This campaign is 100% co-op eligible, and no submission is necessary.',
  //   costs: {
  //     dm: 1.46,
  //     email: 0.13
  //   },
  //   images: {
  //     dmThumb: dmCPOThumb,
  //     dmFull: dmCPO,
  //     emThumb: emCPOThumb,
  //     emFull: emCPO
  //   },
  //   optionalFlag: false
  // },
  // {
  //   order: 3,
  //   active: true,
  //   attributes: {
  //     acquisitionFee: "",
  //     apr: "",
  //     aprCustomerCredit: "",
  //     aprMonths: "",
  //     customerCredit: "",
  //     dueAtSigning: "",
  //     leaseOffer: "",
  //     monthTerm: "",
  //     msrp: "",
  //     securityDeposit: "",
  //     totalOfLeasePayments: "",
  //     vehicle: "",
  //     xxxxModels: ""
  //   },
  //   id: '2019-pre-owned-2',
  //   type: 'Approved Certified Pre-Owned Sales Event',
  //   campaignMatch: 'jaguar_cpo',
  //   title: 'Jaguar Approved Certified Pre-Owned Sales Event',
  //   title2: 'Approved Certified Pre-Owned Sales Event',
  //   hasClimateSelect: false,
  //   description: 'The Jaguar Approved Certified Pre-Owned Sales Event is a 3-month campaign that is localized for your retailer. Please choose two vehicles and model years to focus on, depending on the inventory you want to sell. This campaign highlights the attractive 0.9% APR National Sales Offer for up to 24 months. The conquests are luxury owners of competitor vehicles in market in your AOR. The customer lists are provided by JLRNA and include current CPO owners, disposers and owners of used vehicles in your AOR. This data-driven conquest campaign comes with one direct mail piece and two emails. This campaign is 100% co-op eligible, and no submission is necessary.',
  //   costs: {
  //     dm: 1.46,
  //     email: 0.13
  //   },
  //   images: {
  //     dmThumb: dmCPOThumb,
  //     dmFull: dmCPO,
  //     emThumb: emCPOThumb,
  //     emFull: emCPO
  //   },
  //   optionalFlag: true
  // }
];
